import React from 'react'
import {connect} from 'react-redux'

import {BusinessLineType} from '../../../common/types'
import {TestStatusChoice, TestStatusDropdown} from '../../../Organisms/TestStatus'
import {PermissionTypes, usePermissions} from '../../../Permissions'
import {AppState} from '../../../Root.store'
import {switchView} from '../Order.action'
import {OrderViews} from '../Order.enum'
import {
  removeLookupFilter,
  removeTestStatusFilter,
  selectTestStatusFilter,
  setTestStatusFilter
} from '../Order.filters'

interface Props {
  filter: any
  setFilter: (props: any) => void
  removeFilter: () => void
}
const TestStatusFilter: React.FC<Props> = ({filter = {}, setFilter, removeFilter}) => {
  const {getPermissions} = usePermissions()
  const permissions = getPermissions([PermissionTypes.VIEW_ORDERS_DELIVERIES])
  const parsedBusinessLines = permissions.map(
    (perm: any) => JSON.parse(perm.dataScope).businessLine
  )
  const isRMC = parsedBusinessLines.includes(BusinessLineType.RMC)
  const handleChangeDropdown = (item: TestStatusChoice) => {
    const testStatus = item ? item.value : null
    if (testStatus) {
      setFilter({testStatus})
    } else {
      removeFilter()
    }
  }

  if (!isRMC) {
    return null
  }

  return (
    <TestStatusDropdown
      onChange={handleChangeDropdown}
      selectedTestStatus={filter.testStatus}
      onLight
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  filter: selectTestStatusFilter(state)
})

const mapDispatchToProps = (dispatch) => ({
  setFilter: (props) => {
    dispatch(
      switchView({
        view: OrderViews.DEFAULT,
        clearPrevView: true
      })
    )
    dispatch(removeLookupFilter())
    dispatch(setTestStatusFilter(props))
  },
  removeFilter: () => dispatch(removeTestStatusFilter())
})
export default connect(mapStateToProps, mapDispatchToProps)(TestStatusFilter)
