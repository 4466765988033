/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
import {useNotification, Typography} from '@hconnect/uikit'
import {Box, Button, CircularProgress} from '@material-ui/core'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import {AxiosError} from 'axios'
import moment from 'moment'
import {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {User} from '../../../../../apiclient'
import {trackEvent} from '../../../common/analytics'
import {useCancelOrder} from '../../../common/react-query/hooks/mutations/useCancelOrder'
import {useGetCancellationRequestStatus} from '../../../common/react-query/hooks/queries/useGetCancellaionRequestStatus'
import {useGetOrderCancellationOptions} from '../../../common/react-query/hooks/queries/useGetOrderCancellationOptions'
import {BusinessLineType, BusinessLineTypes} from '../../../common/types'
import {useOrderIntakeStyles} from '../../../Hooks/OrderIntake/useOrderIntakeStyles'
import {OrderCancellationCutOffTime} from '../../../OrderIntake/components/OrderCancellationCutOffTime'
import {OrderCancellation} from '../../../OrderIntake/declarations/types'
import {RequestForQuoteResponse} from '../../../OrderIntake/RequestForQuoteFormValues'
import {
  OrderCancellationStateType,
  useOrderCancellationState
} from '../../../Organisms/Cancellation/OrderCancellationService'
import {useFeaturesState} from '../../../Organisms/Features'
import {AppState} from '../../../Root.store'
import {getUserProfile} from '../../../UserProfile/UserProfile.selectors'
import {useCheckOrdersWithDeliveries} from '../hooks/useCheckOrdersWithDeliveries'
import {Order, ShippingType} from '../Order.types'
interface OrderCancellationButtonProps {
  order: Order
}

export const OrderCancellationButton: React.FC<OrderCancellationButtonProps> = ({order}) => {
  const {t} = useTranslation()
  const {getFeature} = useFeaturesState()
  const {notify} = useNotification()
  const classes = useOrderIntakeStyles()
  const {mutate} = useCancelOrder()
  const userProfile = useSelector<AppState, User | null>(
    (state) => getUserProfile(state).userProfile
  )
  const shippingTypeName = Object.keys(ShippingType).find(
    (key) => ShippingType[key] === order.shippingType
  )
  const {
    data: cancellationOptions,
    isLoading: isLoadingCancellationOptions,
    isError: isErrorCancellationOptions
  } = useGetOrderCancellationOptions(userProfile?.country, order.orgUnitId, shippingTypeName)
  const {data: cancellationRequestStatus, isError: isErrorCancellationRequestStatus} =
    useGetCancellationRequestStatus(
      order.orderNumber,
      order.lineItems[0].itemNumber,
      order.orderStatus
    )

  const {OrderCancellationState, setOrderCancellationState} = useOrderCancellationState()
  const [askForConfirmation, setAskForConfirmation] = useState(false)
  const [cancellationInfoText, setCancellationInfoText] = useState('')
  const [isLoadingCancellation, setIsLoadingCancellation] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [hideCancellationButton, setHideCancellationButton] = useState(false)
  const [isCancellationTimeOver, setIsCancellationTimeOver] = useState(false)

  const orderDeliveriesData = useCheckOrdersWithDeliveries(order)

  const allowedToCancelStatuses = ['pending', 'confirmed', 'reserved']
  const allowedBusinessLines: BusinessLineTypes[] = [BusinessLineType.CEM, BusinessLineType.AGG]
  const notAllowedDivisions = ['70']
  const isOrderStatusAllowsCancellation = allowedToCancelStatuses.includes(order?.orderStatus)
  const isBusinessLineAllowed = order?.businessLine
    ? allowedBusinessLines.includes(order?.businessLine)
    : false
  const isDivisionNotAllowed = order.headerDivisionId
    ? notAllowedDivisions.includes(order.headerDivisionId)
    : true
  const isOrderStatusCancelled = (order?.orderStatus as string) === 'cancelled'
  const isOrderHasDeliveries = orderDeliveriesData.hasDeliveries
  const isSyncroTessOrder = order?.route?.slice(-4) === '0000'
  const isDeliveriesTooFarInProgressToCancel = !!order?.deliveries?.some(
    (delivery) => delivery.overallProcessingStatus === 'C' || delivery.totalGoodsMovement === 'C'
  )
  const isOrderWithDeliveriesCancellationEnabled = getFeature(
    'OrderIntakeOrderCancellationWithDeliveries'
  )
  const isCancelRequestFailedOnCheckSum: boolean = OrderCancellationState.some(
    (item) => item.orderItemNumber === order.orderNumber && item.isFailed
  )
  const isOrderCancellationRequestSucceeded = OrderCancellationState.some(
    (item) => item.orderItemNumber === order.orderNumber && !item.isFailed
  )
  const isCancellationRequestSent = cancellationRequestStatus?.status === 'sent'
  const isCancellationRequestCompleted = cancellationRequestStatus?.status === 'completed'
  const isCancellationRequestFailed = cancellationRequestStatus?.status === 'failed'
  const isCancellationRequestNotFound = cancellationRequestStatus === undefined

  const hideCutOffTime = isErrorCancellationOptions || hideCancellationButton

  const shouldButtonBeDisabled =
    !isOrderStatusAllowsCancellation ||
    isOrderStatusCancelled ||
    isLoadingCancellationOptions ||
    isErrorCancellationOptions ||
    !isBusinessLineAllowed ||
    isCancelRequestFailedOnCheckSum ||
    isCancellationRequestSent

  useEffect(() => {
    /*
    We check first if the button should be disabled.
    */
    setIsButtonDisabled(
      isOrderHasDeliveries
        ? shouldButtonBeDisabled ||
            isSyncroTessOrder ||
            isDeliveriesTooFarInProgressToCancel ||
            !isOrderWithDeliveriesCancellationEnabled
        : shouldButtonBeDisabled
    )

    setIsLoadingCancellation(orderDeliveriesData.isDeliveriesFetching)
    setAskForConfirmation(false)
    setHideCancellationButton(false)

    if (isCancellationRequestSent || isOrderCancellationRequestSucceeded) {
      setIsButtonDisabled(true)
    }

    if (isCancellationRequestCompleted) {
      setHideCancellationButton(true)
      setCancellationInfoText(t('orderIntake.orderCancelledInfo'))
    }

    if (isCancellationRequestFailed && !shouldButtonBeDisabled) {
      setIsButtonDisabled(false)
      setHideCancellationButton(false)
    }

    if (isCancellationRequestNotFound && isCancelRequestFailedOnCheckSum) {
      setIsButtonDisabled(true)
      setCancellationInfoText(t('orderIntake.cancellationFailedCheckSum'))
    }
  }, [
    shouldButtonBeDisabled,
    order,
    isErrorCancellationOptions,
    setIsButtonDisabled,
    isLoadingCancellationOptions,
    isOrderStatusCancelled,
    orderDeliveriesData,
    isCancellationRequestSent,
    isCancellationRequestCompleted,
    isCancellationRequestFailed
  ])

  const cancelOrder = () => {
    const cancelOrderPayload: OrderCancellation = {
      cancelReasonNotes: 'cancellation request',
      orderItemNumber: order.orderNumber,
      orderItemPositionNumber: order.lineItems[0].itemNumber,
      prefix: order.orderId.split('.')[0],
      businessLineCode: order.businessLine,
      checkSum: order.checkSum,
      timeZone: moment.tz.guess()
    }

    setIsLoadingCancellation(true)
    setAskForConfirmation(false)
    trackEvent('hubOrderIntakeCancellationRequestSubmitted', {
      businessLine: order.businessLine,
      cancelledByOrderPlacer: order.orderPlacerName === userProfile?.name,
      country: order.customer.countryId,
      customerId: order.customer.customerId,
      orderId: order.orderId
    })

    mutate(
      {cancelOrderPayload},
      {
        onSuccess: () => {
          setIsLoadingCancellation(false)
          setHideCancellationButton(true)
          notify('success', t('orderIntake.cancellationSucceeded'))
          setCancellationInfoText(t('orderIntake.cancellationProcessing'))

          setOrderCancellationState(
            (currentState: OrderCancellationStateType[]): OrderCancellationStateType[] => {
              const orderIndex = currentState.findIndex(
                (item: OrderCancellationStateType) => item.orderItemNumber === order.orderNumber
              )

              if (orderIndex !== -1) {
                return currentState.map((item: OrderCancellationStateType, index: number) =>
                  index === orderIndex ? {...item, isFailed: false} : item
                )
              } else {
                return [...currentState, {orderItemNumber: order.orderNumber, isFailed: false}]
              }
            }
          )
        },
        onError: (error) => {
          setIsLoadingCancellation(false)

          const errorData = (error as AxiosError).response?.data as RequestForQuoteResponse
          const errorDetails = errorData?.detail

          if (!!errorDetails && errorDetails.includes('CheckSum is invalid')) {
            setIsButtonDisabled(true)
            setOrderCancellationState([
              ...OrderCancellationState,
              {isFailed: true, orderItemNumber: order.orderNumber}
            ])
            notify('error', t('orderIntake.cancellationFailedCheckSum'), {persist: true})
            setCancellationInfoText(t('orderIntake.cancellationFailedCheckSum'))

            trackEvent('hubOrderCancelOrderNotCancelled', {
              errorMessage: errorDetails,
              country: order.customer.countryId,
              customerId: order.customer.customerId,
              orderId: order.orderId
            })
          } else {
            notify('error', t('orderIntake.cancellationFailed'))
          }
        }
      }
    )
  }

  const handleAbortCancellation = () => {
    setAskForConfirmation(false)
  }

  const handleCancellation = () => {
    if (!askForConfirmation) {
      setAskForConfirmation(true)
      return
    } else {
      cancelOrder()
    }
  }

  const getCancellationInfoText = () => {
    if (
      (isCancellationRequestSent || isOrderCancellationRequestSucceeded) &&
      !isCancellationRequestCompleted
    ) {
      return t('orderIntake.cancellationProcessing')
    }
    if (!isOrderStatusAllowsCancellation || isCancellationTimeOver) {
      return t('orderIntake.cancellationNotPossibleStatus')
    }
    if (isOrderStatusCancelled || isCancellationRequestCompleted) {
      return t('orderIntake.orderCancelledInfo')
    }
    if (!isBusinessLineAllowed || isDivisionNotAllowed) {
      return t('orderIntake.cancellationNotPossibleBusinessLine')
    }
    if (
      (isErrorCancellationOptions || isErrorCancellationRequestStatus) &&
      !isCancelRequestFailedOnCheckSum &&
      !isCancellationRequestNotFound
    ) {
      return t('orderIntake.cancellationNotPossibleError')
    }
    if (isOrderHasDeliveries) {
      if (
        isSyncroTessOrder ||
        !isOrderWithDeliveriesCancellationEnabled ||
        isDeliveriesTooFarInProgressToCancel
      ) {
        return t('orderIntake.cancellationNotPossibleDeliveries')
      }
    }
    return cancellationInfoText
  }

  const getConfirmationButtonContent = () => {
    if (isLoadingCancellation) {
      return <CircularProgress size={20} color="inherit" />
    } else if (isOrderStatusCancelled) {
      return t('orderIntake.orderCancelled')
    } else {
      return !askForConfirmation
        ? t('orderIntake.cancelOrder')
        : t('orderIntake.confirmCancellation')
    }
  }

  const handelTimeToCancelOver = (isTimeToCancelOver: boolean) => {
    /*
     If shouldButtonBeDisabled is true, we don't need to check for cut off time
    */
    if (
      shouldButtonBeDisabled ||
      isButtonDisabled ||
      isCancellationRequestSent ||
      (isOrderHasDeliveries &&
        (isSyncroTessOrder ||
          isDeliveriesTooFarInProgressToCancel ||
          !isOrderWithDeliveriesCancellationEnabled))
    )
      return

    setIsButtonDisabled(isTimeToCancelOver)
    setIsCancellationTimeOver(isTimeToCancelOver)
    isTimeToCancelOver && setCancellationInfoText(t('orderIntake.cancellationNotPossibleStatus'))
  }

  return (
    <>
      <Box
        width={1}
        mt={2}
        mb={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        data-test-id="order-cancellation-buttons"
      >
        {!hideCancellationButton && !isOrderStatusCancelled ? (
          <Box width={1} display="flex" flexDirection="column" alignItems="center">
            <Box width={0.9}>
              <Button
                className={classes.cancelOrderButton}
                onClick={handleCancellation}
                startIcon={isLoadingCancellation || isOrderStatusCancelled ? null : <CloseIcon />}
                disabled={isButtonDisabled || isLoadingCancellation}
                data-test-id="cancel-order-button"
              >
                {getConfirmationButtonContent()}
              </Button>
            </Box>
            <Box mt={2} />
            {askForConfirmation && (
              <Box width={0.9}>
                <Button
                  className={classes.orderButton}
                  onClick={handleAbortCancellation}
                  startIcon={<ArrowBackIcon />}
                  data-test-id="abort-cancellation-button"
                >
                  {t('orderIntake.abortCancellation')}
                </Button>
              </Box>
            )}
          </Box>
        ) : null}
        {(isButtonDisabled || hideCancellationButton) && !isOrderStatusCancelled ? (
          <Box textAlign={'center'}>
            <Typography
              variant="body2"
              customColor="textPrimarySoft"
              style={{fontWeight: 700, fontSize: 14}}
              data-test-id="cancellation-info-text"
            >
              {getCancellationInfoText()}
            </Typography>
          </Box>
        ) : null}
        <Box mt={1} />
        {!hideCutOffTime && (
          <OrderCancellationCutOffTime
            order={order}
            onCancelCutOffTimeReached={handelTimeToCancelOver}
            cancellationOptions={cancellationOptions}
            isLoadingCancellationOptions={isLoadingCancellationOptions}
            isCancellationButtonDisabled={shouldButtonBeDisabled || isButtonDisabled}
          />
        )}
      </Box>
    </>
  )
}
