import {trackEvent} from '@hconnect/common/logging/Analytics'
import {LookupFilter as Lookup} from '@hconnect/uikit'
import {ParsedQuery} from 'query-string'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'

import {useFeaturesState} from '../../../Organisms/Features'
import {AppState} from '../../../Root.store'
import {switchView} from '../Order.action'
import {LookupSelectItems, OrderViews} from '../Order.enum'
import {removeLookupFilter, selectLookupFilter, setLookupFilter} from '../Order.filters'

interface LookupFilterType {
  hasError: boolean | undefined
  setFilter: (props: any) => void
  filter: any
  customerId: string
  switchToLookupView: () => void
  switchToDefaultView: () => void
  removeFilter: () => void
  queryParams?: ParsedQuery<string>
  analyticsId?: string
  userId?: string
}

const LookupFilter: React.FC<LookupFilterType> = ({
  hasError,
  setFilter,
  filter = {},
  switchToLookupView,
  switchToDefaultView,
  removeFilter,
  queryParams,
  customerId,
  analyticsId,
  userId
}) => {
  const {t} = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const {getFeature} = useFeaturesState()

  const isCustomerDeliveryNumber = getFeature('CustomerDeliveryNumber')
  const isSearchByCustomerDeliveryNumber = getFeature('SearchByCustomerDeliveryNumber')
  const purchaseOrderFilter = getFeature('PurchaseOrderFilter')

  const selectItems = [
    ...(!isCustomerDeliveryNumber
      ? [
          {
            key: LookupSelectItems.TICKET,
            value: t('order.lookup.ticketNo')
          }
        ]
      : []),
    ...(isCustomerDeliveryNumber
      ? [
          {
            key: LookupSelectItems.BOL,
            value: t('order.lookup.ticketNo')
          }
        ]
      : []),
    ...(purchaseOrderFilter ? [{key: LookupSelectItems.PO, value: t('invoice.lookup.poNo')}] : []),
    {
      key: LookupSelectItems.ORDER,
      value: t('order.lookup.orderNo')
    }
  ]

  const inputLabels = {
    [LookupSelectItems.ORDER]: t('order.lookup.labelorderNo'),
    [LookupSelectItems.TICKET]: t('order.lookup.labelticketNo'),
    [LookupSelectItems.BOL]: t('order.lookup.labelticketNo'),
    [LookupSelectItems.PO]: t('order.lookup.labelpoNo')
  }

  const placeholderLabels = {
    [LookupSelectItems.ORDER]: t('order.lookup.orderPlchld'),
    [LookupSelectItems.TICKET]: t('order.lookup.ticketPlchld'),
    [LookupSelectItems.BOL]: t('order.lookup.ticketPlchld'),
    [LookupSelectItems.PO]: t('order.lookup.poPlchld')
  }

  // find selected filter from redux store or fallback to the first item in list
  const initialSelectedItem =
    selectItems.find((item) => item.key === filter.entity) || selectItems[0]

  const [placeholder, setPlaceholder] = useState(placeholderLabels[LookupSelectItems.ORDER])
  const [lookupEntity, setLookupEntity] = useState<LookupSelectItems | undefined>(
    initialSelectedItem.key
  )

  const [lookupLabel, setLookupLabel] = useState<string>(initialSelectedItem.value)
  const [lookupInputLabel, setInputLookupLabel] = useState<string>(
    inputLabels[initialSelectedItem.key]
  )
  const [lookupInput, setLookupInput] = useState(filter.value)

  useEffect(() => {
    if (queryParams?.orderNumber) {
      setLookupEntity(LookupSelectItems.ORDER)
      setLookupLabel(t('order.lookup.orderNo'))
      setInputLookupLabel(t('order.lookup.labelorderNo'))
      setLookupInput(queryParams.orderNumber)
    }
  }, [queryParams])

  useEffect(() => {
    setLookupInput(filter.value || '')
  }, [filter.value])

  useEffect(() => {
    const value = (lookupInput || '').trim() || filter.value
    if (!value && !queryParams) {
      handleReset()
    }
  }, [lookupInput, queryParams])

  // change the label after language change
  useEffect(() => {
    setInputLookupLabel(inputLabels[initialSelectedItem.key])
    setLookupLabel(initialSelectedItem.value)
  }, [t])

  useEffect(() => {
    if (!queryParams?.orderNumber) {
      handleReset()
    }
  }, [customerId])

  const handleLookupSelectChange = ({key, value}: {key: string; value: string}) => {
    trackEvent('hubLookupSelect', {
      lookupCategory: key,
      lookupSource: 'orders',
      customerId,
      userId,
      analyticsId
    })
    setPlaceholder(placeholderLabels[key])
    setLookupLabel(value)
    setInputLookupLabel(inputLabels[key])
    setLookupEntity(key as LookupSelectItems)
  }

  const removeParams = () => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.has('orderNumber')) {
      searchParams.delete('orderNumber')
      searchParams.delete('customerId')
      history.replace({search: searchParams.toString()})
    }
  }

  const handleReset = () => {
    setLookupInput('')
    switchToDefaultView()
    removeFilter()
  }

  const handleLookup = () => {
    const value = (lookupInput || '').trim()
    if (value && lookupLabel) {
      trackEvent('hubLookupExecute', {
        lookupCategory: lookupEntity,
        lookupValue: value,
        lookupSource: 'orders',
        customerId,
        userId,
        analyticsId
      })
      switchToLookupView()
      if (
        isSearchByCustomerDeliveryNumber &&
        value.length === 10 &&
        lookupEntity === LookupSelectItems.BOL
      ) {
        setFilter({
          entity: LookupSelectItems.TICKET,
          label: lookupLabel,
          value
        })
      } else {
        setFilter({
          entity: lookupEntity,
          label: lookupLabel,
          value
        })
      }
    }
  }
  return (
    <Lookup
      hasError={hasError}
      data-test-id="order-lookup"
      placeholder={placeholder}
      label={lookupInputLabel}
      inputValue={lookupInput}
      onLookup={handleLookup}
      onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setLookupInput(event.target.value)
      }}
      onSelectChange={handleLookupSelectChange}
      selectedKey={lookupEntity}
      onSelectRenderValue={() => <div style={{marginLeft: 31}}>{lookupLabel}</div>}
      onReset={() => {
        handleReset()
        removeParams()
      }}
      selectItems={selectItems}
      {...{style: {height: '100%'}}}
    />
  )
}
const mapStateToProps = (state: AppState) => ({
  filter: selectLookupFilter(state)
})
const mapDispatchToProps = (dispatch) => ({
  setFilter: (props) => dispatch(setLookupFilter(props)),
  switchToLookupView: () => dispatch(switchView({view: OrderViews.LOOKUP})),
  switchToDefaultView: () =>
    dispatch(
      switchView({
        view: OrderViews.DEFAULT,
        clearPrevView: true
      })
    ),
  removeFilter: () => dispatch(removeLookupFilter())
})
export default connect(mapStateToProps, mapDispatchToProps)(LookupFilter)
