import {Typography} from '@hconnect/uikit'
import {Box, styled} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'

import {Customer} from '../../../Organisms/Customers/Customers.types'
import CustomersFilter from '../../../Organisms/Customers/CustomersFilter'

import SelectedCustomer from './SelectedCustomer'

const StyledH4 = styled(Typography)({
  letterSpacing: '0.5px',
  textTransform: 'none'
})

interface TitleProps {
  t: TFunction
  customer?: Customer
  loading: boolean
  showPayers: boolean
  salesAgent?: boolean
  smallScreen?: boolean
}
export const OverviewTitle: React.FC<TitleProps> = ({
  t,
  customer,
  loading,
  showPayers,
  salesAgent,
  smallScreen = false
}) => {
  if (!customer) {
    return null
  }
  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <StyledH4 variant="h4" customColor="textSecondarySoft">
          {t('overview.accountNumber')} {customer.customerNumber}
        </StyledH4>
      </Box>

      <Box display="flex" justifyContent="flex-start" alignItems="center">
        {!salesAgent ? <Box mr={0.5}>{smallScreen ? null : t('overview.titleFor')}</Box> : null}
        {!salesAgent ? (
          <CustomersFilter
            dark
            long
            onLight={false}
            data-test-id="overview-filter-customers"
            loading={loading}
            showTitleOnlyEnabled
            customersOnly={!showPayers}
            smallScreen={smallScreen}
          />
        ) : (
          <SelectedCustomer customer={customer} />
        )}
      </Box>
    </Box>
  )
}
